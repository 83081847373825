import React from "react";
import { useSendContext } from ".";
import { ErrorMessage, Formik } from "formik";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import * as Yup from "yup";
import { getBeneficiaries } from "../../../server";
import SecondaryBtn from "../../buttons/SecondaryBtn";
import PrimaryBtn from "../../buttons/PrimaryBtn";

const SelectBeneficiary = ({ toggleModal, updateStep, reset }) => {
	const { setDestinationInfo, currencies } = useSendContext();

	const initialValues = {
		id: "",
		account: null,
	};
	const validationSchema = Yup.object().shape({
		id: Yup.string().required("Select a beneficiary to send to"),
	});

	const filterOptions = debounce(async (searchText) => {
		const data = await getBeneficiaries({ searchText: searchText, currency: currencies.destination });
		const result = data?.data?.beneficiary?.map((account) => ({
			value: account.id,
			label: `${account?.name} (${account?.account_number})`,
			object: account,
		}));
		return new Promise((resolve) => {
			resolve(result);
		});
	}, 200);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={({ account }) => {
				setDestinationInfo(
					currencies.destination === "NGN"
						? {
								name: account?.name,
								destinationAccount: account?.account_number,
								bankCode: account?.bank_code,
								destinationBank: account?.bank_name,
						  }
						: {
								name: account?.name,
								account: account?.account_number,
								routing: account?.routing_no,
								bank: account?.bank_name,
						  }
				);
				updateStep(2);
			}}
		>
			{({ setFieldValue, handleSubmit, submitForm }) => (
				<form className="px-2 bg-white" onSubmit={handleSubmit}>
					<h5 className="text-xl font-medium text-grey-title">Search for beneficiary</h5>
					<p className="mb-6 text-sm leading-7 text-grey-title">Type beneficiary name</p>
					<p className="my-2 text-xs font-medium tracking-wider uppercase text-grey-lightGray">Beneficary</p>
					<AsyncSelect
						isClearable
						placeholder="Enter beneficiary name"
						loadOptions={filterOptions}
						styles={{
							container: (baseStyles) => ({
								...baseStyles,
								flex: 1,
							}),
							control: (baseStyles) => ({
								...baseStyles,
								border: "1px solid #e5e7eb",
							}),
							placeholder: (baseStyles) => ({
								...baseStyles,
								fontSize: "0.875rem",
								fontWeight: 500,
								color: "black",
							}),
							valueContainer: (baseStyles) => ({
								...baseStyles,
								padding: "4px 8px",
								backgroundColor: "rgba(224, 231, 255, 0.2)",
							}),
							singleValue: (baseStyles) => ({
								...baseStyles,
								fontSize: "0.875rem",
								fontWeight: 500,
								color: "black",
							}),
						}}
						components={{
							DropdownIndicator: null,
						}}
						onChange={(value) => {
							setFieldValue("id", value?.value);
							setFieldValue("account", value?.object);
						}}
					/>
					<ErrorMessage name="id" component="div" className="text-red-500" />

					<div className="flex items-center w-full my-8 space-between gap-x-4">
						<SecondaryBtn type="button" placeholder="Back" style={{ width: "50%" }} onClick={() => updateStep(2)} />
						<PrimaryBtn type="button" placeholder="Select" style={{ width: "50%", height: "2.5rem" }} onClick={() => submitForm()} />
					</div>
				</form>
			)}
		</Formik>
	);
};

export default SelectBeneficiary;
